
  import { Component, Vue } from 'vue-property-decorator'
  import Tile from '@/components/common/Tile/Tile.vue'
  import Passthrough from '@/views/Passthrough.vue'
  import { resolveDefaultRegionId } from '@/router/utils'
  import { Route } from 'vue-router'
  import { asyncRouteGuard } from '@/plugins/async-route-guard'

  @Component({
    components: {
      Passthrough,
      Tile
    }
  })
  export default class IncidentService extends Vue {
    async beforeRouteUpdate(to: Route, from: Route, next: (p?: any) => void) {
      await asyncRouteGuard(resolveDefaultRegionId)(to, from, next)
    }
  }
