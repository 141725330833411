import store, { oidcNamespace } from '@/store/index'
import AssessmentsApiClient from '@/api/zbag-prd/assessments/AssessmentsApiClient'
import IncidentsApiClient from '@/api/zbag-prd/incidents/IncidentsApiClient'
import InspectionsApiClient from '@/api/zbag-prd/inspections/InspectionsApiClient'
import IssuesApiClient from '@/api/zbag-prd/issues/IssuesApiClient'
import RegionsApiClient from '@/api/zbag-prd/regions/RegionsApiClient'
import UsersApiClient from '@/api/zbag-prd/users/UsersApiClient'
import SitesApiClient from '@/api/zbag-prd/sites/SitesApiClient'

export const assessmentsClient = new AssessmentsApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

export const incidentsClient = new IncidentsApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

export const inspectionsClient = new InspectionsApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

export const issuesClient = new IssuesApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

export const regionsClient = new RegionsApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

export const usersClient = new UsersApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

export const sitesClient = new SitesApiClient(
  runtimeAppSettings.apiBaseUrl,
  () => store.getters[`${oidcNamespace}/oidcAccessToken`]
)

assessmentsClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})

incidentsClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})

inspectionsClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})

issuesClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})

regionsClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})

usersClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})

sitesClient.registerErrorHandler(error => {
  store.commit('setAppError', { error })
})
