import { Module } from 'vuex'

import { IRootState } from '@/store/types'
import { IIncidentServiceState } from '@/areas/IncidentService/store/types'
import mutations from '@/areas/IncidentService/store/mutations'
import actions from '@/areas/IncidentService/store/actions'
import getters from '@/areas/IncidentService/store/getters'

const incidentServiceModule: Module<IIncidentServiceState, IRootState> = {
  namespaced: true,
  state: {
    overview: {
      incidents: [],
      filter: null
    },
    incidentDetail: null,
    sites: [],
    incidentMediaUploading: [],
    incidentAttachmentsUploading: []
  },
  getters,
  mutations,
  actions
}

export default incidentServiceModule
