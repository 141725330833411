
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
  import { Location } from 'vue-router'

  @Component({
    inheritAttrs: false
  })
  export default class SiteMarker extends Vue {
    @Prop(String) siteId!: string
    @Prop(Boolean) xxSmall!: boolean
    @Prop(Object) to!: Location
    @Prop(Boolean) showMeta!: boolean
    @Prop(Boolean) technicalControlRunDone!: boolean
    @Prop(Boolean) finalControlRunDone!: boolean
    @Prop(Number) openIssuesCount!: number
    @Prop(Boolean) isSelected!: boolean

    @Emit('click')
    emitClick(siteId: string) {
      return siteId
    }

    onClick() {
      if (!this.$listeners.click) {
        // return if parent didn't register any click handlers
        return
      }

      this.emitClick(this.siteId)
    }
  }
