
  import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
  import { OwnObservations } from '@/model'
  import WindDirectionSelector from '@/components/impressions/WindDirectionSelector.vue'

  @Component({
    components: { WindDirectionSelector }
  })
  export default class Impressions extends Vue {
    @Prop()
    public editMode!: boolean

    @Prop()
    public ownObservations!: OwnObservations

    cloudObservation: string | null = ''
    windStrengthObservation: string | null = ''

    public get weatherObservations(): string[] {
      return this.ownObservations.weatherObservations
    }

    public set weatherObservations(values: string[]) {
      this.$emit('update:weather-observations', values)
    }

    @Watch('ownObservations.cloudObservation', { immediate: true, deep: true })
    watchCloudObservation(cloudObservationFromProps: string): void {
      this.cloudObservation = cloudObservationFromProps
    }

    public updateCloudObservation(value: string | null) {
      this.cloudObservation = value
      this.$emit('update:cloud-observation', value)
    }

    @Watch('ownObservations.windStrengthObservation', { immediate: true, deep: true })
    watchWindStrengthObservation(windStrengthObservationFromProps: string): void {
      this.windStrengthObservation = windStrengthObservationFromProps
    }

    updateWindStrengthObservation(value: string | null) {
      this.windStrengthObservation = value
      this.$emit('update:wind-strength-observation', value)
    }

    public updateWindDirectionObservation(value: number | null) {
      this.$emit('update:wind-direction-observation', value)
    }

    public get snowPackObservations(): string[] {
      return this.ownObservations.snowPackObservations
    }

    public set snowPackObservations(values: string[]) {
      this.$emit('update:snowpack-observations', values)
    }

    public get avalancheActivityObservations(): string[] {
      return this.ownObservations.avalancheActivityObservations
    }

    public set avalancheActivityObservations(values: string[]) {
      this.$emit('update:avalanche-activity-observations', values)
    }

    public get avalancheProblemObservations(): string[] {
      return this.ownObservations.avalancheProblemObservations
    }

    public set avalancheProblemObservations(values: string[]) {
      this.$emit('update:avalanche-problem-observations', values)
    }
  }
