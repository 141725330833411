import BaseApiClient from '@/api/utils/BaseApiClient'
import { IGetSingleSiteRequestDto, ISiteDetailDto, ISiteListDto } from '@/api/zbag-prd/sites/types'
import { ISite } from '@/areas/SiteService/store/types'
import { hydrateSites } from '@/api/zbag-prd/sites/utils'
import { IRawGeojsonFeatureCollection } from '@/store/types'

export default class SitesApiClient extends BaseApiClient {
  async getSites(regionId: string, includeStatistics: boolean = false): Promise<ISite[]> {
    let urlToQuery = `${this.baseUrl}/regions/${regionId}/sites`

    if (includeStatistics) {
      urlToQuery += `?includeStatistics=true`
    }

    const response = await this.fetch<ISiteListDto>(urlToQuery, this.requestOptions('GET'))

    if (response && response.sites) {
      return hydrateSites(response?.sites)
    }

    return []
  }

  async getSingleSite({ regionId, siteId }: IGetSingleSiteRequestDto): Promise<ISite | null> {
    const response = await this.fetch<ISiteDetailDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}`,
      this.requestOptions('GET')
    )

    if (response) {
      return hydrateSites([response])[0]
    }

    return null
  }

  async getSitesGeojson(): Promise<IRawGeojsonFeatureCollection | undefined> {
    return await this.fetch<IRawGeojsonFeatureCollection>(
      `${this.baseUrl}/regions/sites-geodata`,
      this.requestOptions('GET')
    )
  }

  async getAllSites(): Promise<ISite[]> {
    const response = await this.fetch<ISiteListDto>(`${this.baseUrl}/regions/sites`, this.requestOptions('GET'))

    if (response && response.sites) {
      return hydrateSites(response.sites)
    }

    return []
  }
}
