import { MutationTree } from 'vuex'
import { IRawGeojsonFeatureCollection, IRegion, IRootState } from '@/store/types'
import { ZbagUser } from '@/areas/Oidc/store/types'
import { User } from '@/model'
import { AppErrorKind } from '@/api/utils/types'

interface IAddSiteIdsToRegionMutation {
  regionId: string
  siteIds: string[]
}

interface IAddIssueIdsToRegionMutation {
  regionId: string
  issueIds: string[]
}

export interface ISetAppErrorMutation {
  message?: string
  error: AppErrorKind
}

interface IAddSitesGeojsonMutation {
  geojson: IRawGeojsonFeatureCollection
}

const rootMutations: MutationTree<IRootState> = {
  setCurrentUser(state, user: ZbagUser): void {
    state.currentUser = user
  },
  setRegions(state, regions: IRegion[]): void {
    const regionsToSet = regions.map(region => ({ ...region, siteIds: [], issueIds: [] }))
    state.regions = regionsToSet
  },
  addSiteIdsToRegion(state, { siteIds, regionId }: IAddSiteIdsToRegionMutation): void {
    if (!siteIds || !siteIds.length) {
      return
    }

    const region = state.regions.find(region => region.id === regionId)

    if (region) {
      region.siteIds = siteIds
    }
  },
  setUsers(state, users: User[]) {
    state.users = users
  },
  addIssueIdsToRegion(state, { issueIds, regionId }: IAddIssueIdsToRegionMutation): void {
    if (!issueIds || !issueIds.length || !regionId) {
      return
    }

    const region = state.regions.find(region => region.id === regionId)

    if (region) {
      region.issueIds = issueIds
    }
  },
  setAppError(state: IRootState, payload: ISetAppErrorMutation | null) {
    if (!payload) {
      state.appError = null
      state.appErrorMessage = null
      return
    }

    state.appError = payload.error

    if (typeof payload.message !== 'undefined') {
      state.appErrorMessage = payload.message
    }
  },
  selectRegion(state: IRootState, newRegion: IRegion | null) {
    if (!newRegion) {
      return
    }

    if (newRegion !== state.selectedRegion) {
      state.selectedRegion = newRegion
    }
  },
  addSitesGeojson(state, { geojson }: IAddSitesGeojsonMutation): void {
    state.sitesGeojson = geojson
  }
}

export default rootMutations
