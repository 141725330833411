import Vue from 'vue'
import { AppErrorKind } from '@/api/utils/types'
import ConnectionError from '@/components/common/Errors/ConnectionError.vue'
import ResourceNotFoundError from '@/components/common/Errors/ResourceNotFoundError.vue'
import GenericError from '@/components/common/Errors/GenericError.vue'
import ValidationError from '@/components/common/Errors/ValidationError.vue'
import ChunkLoadError from '@/components/common/Errors/ChunkLoadError.vue'

export interface IProps {
  appError: AppErrorKind
  appErrorMessage: string
  setAppError: (value: null) => void
}

export default Vue.extend<IProps>({
  functional: true,
  render: function(createElement, context) {
    const message = context.props.appErrorMessage

    function appropriateErrorComponent() {
      const appError = context.props.appError

      if (appError === AppErrorKind.ConnectionProblem) return ConnectionError
      if (appError === AppErrorKind.ResourceNotFound) return ResourceNotFoundError
      if (appError === AppErrorKind.ValidationError) return ValidationError
      if (appError === AppErrorKind.ChunkLoadError) return ChunkLoadError

      return GenericError
    }

    return createElement(appropriateErrorComponent(), {
      props: {
        isVisible: !!context.props.appError,
        message
      },
      on: {
        'update:isVisible': function updateVisibility() {
          // we disregard the value passed by the caller here assuming the only action you can do is closing the snackbar
          context.props.setAppError(null)
        }
      }
    })
  }
})
