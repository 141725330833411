
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { SnowStation } from '@/model'
  import DataPointViz from '@/components/DataPointViz.vue'
  import ShowMoreButton from '@/components/ShowMoreButton.vue'
  @Component({
    components: { ShowMoreButton, DataPointViz }
  })
  export default class MeasurementTable extends Vue {
    @Prop()
    public headers!: any[]

    @Prop()
    public items!: SnowStation[]
  }
