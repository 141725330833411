import { IIssue } from '@/areas/SiteService/store/types'
import { parseISO } from 'date-fns'
import { IIssueDto } from '@/api/zbag-prd/issues/types'

export const hydrateIssues = (issues: IIssueDto[]): IIssue[] => {
  const hydratedIssues = issues.map(issue => {
    const createdDate = parseISO(issue.createdAt)
    const resolvedDate = issue.resolvedAt ? parseISO(issue.resolvedAt) : null
    const siteId = issue.site
    const attachments = issue.attachments || []

    return {
      ...issue,
      siteId,
      createdAt: createdDate,
      resolvedAt: resolvedDate,
      attachments
    }
  })

  return hydratedIssues
}
