
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
  import DataPointViz from '@/components/DataPointViz.vue'
  import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue'
  import SnowChart from '@/components/SnowChart.vue'
  import ActionPackageSelector from '@/components/ActionPackageSelector.vue'
  import { Assessment, IActionPackage, IRequiredMeasures } from '@/model'
  import { cloneDeep } from 'lodash-es'
  @Component({
    components: {
      SnowChart,
      DataPointListTimeViz,
      ActionPackageSelector,
      DataPointViz
    }
  })
  export default class RequiredMeasuresSelector extends Vue {
    @Prop()
    public editMode!: boolean

    @Prop()
    public assessment!: Assessment

    @Watch('assessment.courseOfAction.requiredMeasures', { immediate: true, deep: true })
    public watchRequiredMeasures(requiredMeasuresFromProps: Assessment['courseOfAction']['requiredMeasures']): void {
      if (requiredMeasuresFromProps) {
        this.deployWarningSingsSwitchState = requiredMeasuresFromProps.deployWarningSigns
        this.requiredMeasuresSwitchState = true
      } else {
        this.deployWarningSingsSwitchState = false
        this.requiredMeasuresSwitchState = false
      }
    }

    public displayRemoveDialog = false
    public requiredMeasuresSwitchState = false
    public deployWarningSingsSwitchState = false

    public get affectedRegion(): string {
      return this.assessment.courseOfAction.requiredMeasures
        ? this.assessment.courseOfAction.requiredMeasures.affectedPartOfRegion
        : ''
    }

    public set affectedRegion(affectedRegion: string) {
      this.updateRequiredMeasures(requiredMeasures => (requiredMeasures.affectedPartOfRegion = affectedRegion))
    }

    public get actionPackages(): IActionPackage[] {
      return this.assessment.courseOfAction.requiredMeasures
        ? this.assessment.courseOfAction.requiredMeasures.actionPackages
        : []
    }

    public toggleWarningSignsState(state: boolean): void {
      this.deployWarningSingsSwitchState = state
      this.updateRequiredMeasures(requiredMeasures => (requiredMeasures.deployWarningSigns = state))
    }

    public toggleRequiredMeasures(status: boolean) {
      if (status) {
        this.requiredMeasuresSwitchState = true
        this.emitUpdate({
          actionPackages: [],
          deployWarningSigns: false,
          affectedPartOfRegion: ''
        })
      } else {
        this.requiredMeasuresSwitchState = false
        this.displayRemoveDialog = true
      }
    }

    public deleteRequiredMeasures() {
      this.displayRemoveDialog = false
      this.requiredMeasuresSwitchState = false
      this.emitUpdate(null)
    }

    public cancelRequiredMeasuresDeletion() {
      this.displayRemoveDialog = false
      this.requiredMeasuresSwitchState = true
    }

    private updateActionPackages(actionPackages: IActionPackage[]) {
      this.updateRequiredMeasures(requiredMeasures => (requiredMeasures.actionPackages = actionPackages))
    }

    @Emit('update:required-measures')
    private emitUpdate(requiredMeasures: IRequiredMeasures | null): IRequiredMeasures | null {
      return requiredMeasures
    }

    private updateRequiredMeasures(updater: (requiredMeasures: IRequiredMeasures) => void) {
      let requiredMeasures = this.getRequiredMeasuresCopy()
      if (requiredMeasures) {
        updater(requiredMeasures)
        this.emitUpdate(requiredMeasures)
      }
    }

    private getRequiredMeasuresCopy(): IRequiredMeasures {
      return cloneDeep(this.assessment.courseOfAction.requiredMeasures) as IRequiredMeasures
    }
  }
