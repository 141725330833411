
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

  @Component
  export default class ExpositionSelector extends Vue {
    get expositions() {
      return this.selectedExpositions
    }

    @Prop()
    public editMode?: boolean

    @Prop()
    public expositionsProp!: string[]

    @Watch('expositionsProp', { immediate: true, deep: true })
    watchExpositionsProp(expositionsPropFromProps: string[]): void {
      this.selectedExpositions = [...expositionsPropFromProps]
    }

    private selectedExpositions: string[] = []

    private mapping: Map<number, string> = new Map([
      [7, 'NE'],
      [8, 'E'],
      [1, 'SE'],
      [2, 'S'],
      [3, 'SW'],
      [4, 'W'],
      [5, 'NW'],
      [6, 'N']
    ])

    public hasExposition(index: number): boolean {
      let exposition = this.mapping.get(index)

      return exposition === undefined ? false : this.expositions.includes(exposition)
    }

    public toggleCoreExpoZone(index: number) {
      const exposition = this.mapping.get(index)

      if (exposition === undefined) {
        return
      }

      let position = this.selectedExpositions.indexOf(exposition)

      if (position >= 0) {
        this.selectedExpositions.splice(position, 1)
      } else {
        this.selectedExpositions.push(exposition)
      }

      this.$emit('update:expositions', this.selectedExpositions)
    }
  }
