
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { OwnObservations } from '@/model'
  @Component
  export default class WindDirectionSelector extends Vue {
    @Prop()
    public editMode!: boolean

    @Prop()
    public ownObservations!: OwnObservations

    public get windDirectionObservation(): number | null {
      return this.ownObservations.windDirectionObservation
    }

    public set windDirectionObservation(values: number | null) {
      this.$emit('update:wind-direction-observation', values)
    }
  }
