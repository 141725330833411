import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'
import { get } from 'ol/proj'
import { WMTS } from 'ol/source'
import WmtsTileGrid from 'ol/tilegrid/WMTS'
import OlMap from 'ol/Map'
import View from 'ol/View'
import { defaults as defaultInteractions } from 'ol/interaction'
import { defaults as defaultControls } from 'ol/control'
import { Extent, buffer as olBuffer } from 'ol/extent'
import TileLayer from 'ol/layer/Tile'
import Projection from 'ol/proj/Projection'

// Register CH1903+ (aka EPSG:2056) coordinate reference system in proj4 (see also https://epsg.io/2056)
proj4.defs(
  'EPSG:2056',
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'
)

// Make proj4 projections available to openlayers
register(proj4)

/**
 * Creates a Swisstopo map with the Zermatt region zoomed into view. The map is not yet attached to a DOM element, this
 * must be done by calling setTarget() on the map object that is returned from this function.
 */
export function createZermattMap(initialViewExtent: Extent) {
  const ch1903Extent: Extent = [2420000, 130000, 2900000, 1350000] // as defined in https://epsg.io/2056
  const projection = get('EPSG:2056')!
  const viewExtent = olBuffer(initialViewExtent, 18500) // initialViewExtent extended by custom value so that the entire Zermatt skiarea fits onto the lowest (most zoomed-out) level
  const swisstopoLayer = defineSwisstopoLayer(projection, viewExtent)

  projection.setExtent(ch1903Extent)

  const view = new View({
    projection: projection,
    minZoom: 6,
    maxZoom: 11, // Increase if a 1:10'000 map is needed
    zoom: 9,
    center: [2622729, 1094476], // Zermatt, Furi (overridden by view.fit() below, but an initial center is required)
    extent: viewExtent // Restrict user to the area around the displayed map
  })

  const map = new OlMap({
    layers: [swisstopoLayer],
    view: view,
    controls: defaultControls({ zoom: false, attribution: false }),
    interactions: defaultInteractions({ pinchRotate: false })
  })

  return map
}

function defineSwisstopoLayer(projection: Projection, layerExtent: Extent) {
  // Define the available resolutions as listed on https://api3.geo.admin.ch/api/faq/index.html.
  // Note that not all resolutions are used per the view's minZoom and maxZoom properties
  const resolutions = [
    4000,
    3750,
    3500,
    3250,
    3000,
    2750,
    2500,
    2250,
    2000,
    1750,
    1500,
    1250,
    1000,
    750,
    650,
    500,
    250,
    100,
    50,
    20,
    10,
    5,
    2.5,
    2,
    1.5,
    1,
    0.5,
    0.25
  ]

  const matrixIds = resolutions.map((r, i) => i.toString())

  return new TileLayer({
    source: new WMTS({
      layer: 'ch.swisstopo.pixelkarte-farbe-winter',
      crossOrigin: 'anonymous',
      url: 'https://wmts{5-9}.geo.admin.ch/1.0.0/{Layer}/default/current/2056/{TileMatrix}/{TileCol}/{TileRow}.jpeg',
      tileGrid: new WmtsTileGrid({
        origin: [2420000, 1350000],
        resolutions: resolutions,
        matrixIds: matrixIds
      }),
      requestEncoding: 'REST',
      projection: projection,
      matrixSet: '',
      style: ''
    }),
    extent: layerExtent, // Clip layer to reduce Swisstopo quota usage
    className: 'swisstopo-layer'
  })
}
