
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { IDataPoint } from '@/model'
  import { formatRelative, format } from '@/plugins/format'

  @Component
  export default class DataPointListTimeViz extends Vue {
    @Prop()
    public dataPoints!: IDataPoint[]

    private relevantDataPoint: IDataPoint | null = null

    formatRelative = formatRelative
    format = format

    public mounted() {
      if (!this.dataPoints) {
        return
      }

      let filteredSorted = this.dataPoints.filter(p => p).sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))

      this.relevantDataPoint = filteredSorted ? filteredSorted[0] : null
    }
  }
