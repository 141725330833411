import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'
import { UserVuetifyPreset } from 'vuetify'

export const config: UserVuetifyPreset = {
  icons: {
    iconfont: 'mdiSvg'
  },
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1b5c88', // 100% CMYK: 80-45-0-40
        secondary: '#7990b5', // 60% CMYK: 80-45-0-40
        accent: '#71bae8', // 80% CMYK 70-15-0-0
        error: '#e41617', // 100% CMYK 0-95-100-0
        info: '#22aae2', // 100% CMYK 70-15-0-0
        success: '#bbb459', // 100% CMYK 20-10-70-20
        warning: '#e7963f' // 80% CMYK 10-60-100-0
      }
    }
  }
}

export default new Vuetify(config)
