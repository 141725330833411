import { ActionTree } from 'vuex'

import { IRootState } from '@/store/types'
import {
  IFilterPayload,
  IIncidentDetail,
  IIncidentServiceState,
  IncidentUploadingKeys
} from '@/areas/IncidentService/store/types'
import {
  IAssignRescuerRequestDto,
  ICreateIncidentAttachmentDto,
  ICreateIncidentRequestDto,
  IDeleteIncidentAttachmentDto,
  IDeleteIncidentDto,
  IUpdateIncidentRequestDto,
  IUpdateIncidentStatusDto
} from '@/api/zbag-prd/incidents/types'
import { incidentsClient, sitesClient } from '@/api/zbag-prd/httpClients'
import { Regions } from '@/model'
import { IUpdateStatusMutation } from '@/areas/IncidentService/store/mutations'

export interface IUploadIncidentAttachmentsPayload extends ICreateIncidentAttachmentDto {
  stateKey: IncidentUploadingKeys
}

const actions: ActionTree<IIncidentServiceState, IRootState> = {
  async fetchIncidentsOverviewData(
    { commit, dispatch, getters, rootState, rootGetters },
    { regionId, rescuer, from, to, offset, status }: IFilterPayload
  ): Promise<void> {
    const currentRegionId = regionId || rootGetters.currentRegionId
    const incidents = await incidentsClient.getIncidents({
      to: to ? to.toISOString() : undefined,
      from: from ? from.toISOString() : undefined,
      offset: offset ? offset : undefined,
      limit: 20,
      status,
      rescuer,
      regionId: currentRegionId !== Regions.Winter ? currentRegionId : undefined
    })

    if (incidents) {
      commit('addIncidentsOverviewData', {
        incidents
      })
    }
  },
  async fetchAllSites({ commit }): Promise<void> {
    const sites = await sitesClient.getAllSites()

    commit('addAllSites', {
      sites
    })
  },
  async fetchIncidentDetail({ commit }, incidentId: string): Promise<void> {
    const incident = await incidentsClient.getIncident(incidentId)

    if (incident) {
      commit('addIncidentDetail', { incident })
    }
  },
  async createIncident({ commit }, payload: ICreateIncidentRequestDto): Promise<IIncidentDetail | null> {
    const incident = await incidentsClient.createIncident(payload)

    if (incident) {
      commit('addIncidentDetail', { incident })

      // return incident so that the caller has the id immediately,
      // and can redirect to the detail view
      return incident
    }

    return null
  },
  async updateIncident({ commit }, payload: IUpdateIncidentRequestDto): Promise<IIncidentDetail | null> {
    const incident = await incidentsClient.updateIncident(payload)

    if (incident) {
      commit('addIncidentDetail', { incident })
    }

    return incident
  },
  async assignRescuer({ commit }, payload: IAssignRescuerRequestDto): Promise<void> {
    const result = await incidentsClient.assignRescuer(payload)

    if (result) {
      commit('assignRescuer', { rescuer: result })
    }
  },
  async unAssignRescuer({ commit }, payload: IAssignRescuerRequestDto): Promise<void> {
    try {
      await incidentsClient.unAssignRescuer(payload)
      commit('unAssignRescuer', { userId: payload.userId })
    } catch (e) {
      // error already handled by API client
    }
  },
  async uploadIncidentAttachment({ commit }, payload: IUploadIncidentAttachmentsPayload): Promise<void> {
    const fileName = payload.file.name
    let attachment = null

    commit('addPendingUpload', { name: fileName, stateKey: payload.stateKey })

    try {
      attachment = await incidentsClient.createIncidentAttachment(payload)
      commit('removePendingUpload', { name: fileName, stateKey: payload.stateKey })
      commit('addAttachment', { attachment })
    } catch (e) {
      commit('removePendingUpload', { name: fileName, stateKey: payload.stateKey })
    }
  },
  async deleteIncidentAttachment({ commit }, payload: IDeleteIncidentAttachmentDto): Promise<void> {
    try {
      await incidentsClient.deleteIncidentAttachment(payload)
      commit('removeAttachment', { attachmentId: payload.attachmentId })
    } catch (e) {
      // error already handled by API request
      return
    }
  },
  async updateStatus({ commit }, payload: IUpdateIncidentStatusDto): Promise<void> {
    try {
      const response = await incidentsClient.updateStatus(payload)

      if (response) {
        const { status, signedAt, resolvedAt } = response
        commit('updateIncidentStatus', { status, signedAt, resolvedAt } as IUpdateStatusMutation)
      }
    } catch (e) {
      // error already handled by API request
      return
    }
  },
  async deleteIncident({ commit }, payload: IDeleteIncidentDto): Promise<void> {
    return await incidentsClient.deleteIncident(payload)
  },
  async startSbsExport(): Promise<void> {
    return await incidentsClient.exportIncidentToSbs()
  }
}

export default actions
