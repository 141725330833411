
  import { Vue, Component } from 'vue-property-decorator'
  import { Getter } from 'vuex-class'
  import Feature from 'ol/Feature'
  import Geometry from 'ol/geom/Geometry'

  import SwissTopoMap from '@/components/common/SwissTopoMap/SwissTopoMap.vue'
  import PositionMarker from '@/components/common/SwissTopoMap/PositionMarker.vue'
  import MapSiteLayer from '@/components/common/SwissTopoMap/MapSiteLayer.vue'
  import MapSiteOverlays from '@/components/common/SwissTopoMap/MapSiteOverlays.vue'
  import { ICustomGeojsonProperties, IRawGeojsonFeatureCollection } from '@/store/types'
  import { incidentServiceNamespace } from '@/store'
  import { IIncidentDetail, IPersonalData } from '@/areas/IncidentService/store/types'
  import { format, siteDisplayName } from '@/plugins/format'
  import { RouteNames } from '@/router/constants'
  import { User } from '@/model'
  import { ISite } from '@/areas/SiteService/store/types'

  const reportClass = 'gp-collision-report'

  @Component({
    components: { MapSiteOverlays, MapSiteLayer, PositionMarker, SwissTopoMap }
  })
  export default class IncidentCollisionReport extends Vue {
    @Getter('incidentDetail', { namespace: incidentServiceNamespace }) incidentDetail!: IIncidentDetail | null
    @Getter('sitesGeojson') sitesGeojson!: IRawGeojsonFeatureCollection
    @Getter('allSites', { namespace: incidentServiceNamespace }) allSites!: ISite[]

    mounted() {
      // toggle class on body to isolate global styles
      this.toggleReportClass()

      // defer print() to ensure map can load tiles beforehand
      setTimeout(() => {
        window.print()
      }, 1000)
    }

    beforeDestroy() {
      // toggle class on body to islolate global styles
      this.toggleReportClass()
    }

    format = format

    get currentDate() {
      return format(new Date(), 'PP')
    }

    get breadcrumbs() {
      return [
        {
          to: {
            name: RouteNames.IncidentServiceIncident,
            params: { incidentId: String(this.incidentDetail?.incidentId) }
          },
          text: `< ${this.$t('incidentService.incidentCollisionReport.backToIncident')}`,
          disabled: false,
          exact: true
        }
      ]
    }

    get rescueServiceArrivalTime() {
      const arrival = this.incidentDetail?.rescuers
        .slice()
        .filter(rescuer => !!rescuer.arrivedAt)
        .sort((a, b) => (a.arrivedAt! >= b.arrivedAt! ? 1 : -1))[0]?.arrivedAt

      return arrival ? format(arrival, 'p') : null
    }

    get siteName(): string | undefined {
      if (this.incidentDetail) {
        const site = this.allSites.find(site => site.id === this.incidentDetail!.siteId)

        if (site) {
          return siteDisplayName(site)
        }
      }
    }

    get sport() {
      return this.incidentDetail?.sport
        ? this.$t(`incidentService.updateIncident.sportChoices.${this.incidentDetail.sport}`)
        : null
    }

    get injuriesList() {
      return this.incidentDetail?.injuries.length
        ? this.incidentDetail?.injuries.map(
            injury =>
              `${this.$t(`incidentService.updateIncident.injuryChoices.${injury.typeOfInjury}`)} (${this.$t(
                `incidentService.updateIncident.bodyPartChoices.${injury.affectedBodyPart}`
              )})`
          )
        : []
    }

    get involvedSortedByRole() {
      return this.incidentDetail?.involvedPeople.slice().sort((a, b) => (a.role >= b.role ? 1 : -1)) ?? []
    }

    get handOverOrderTime() {
      return this.incidentDetail?.handOverOrderTime ? format(this.incidentDetail.handOverOrderTime, 'p') : '-'
    }

    get handOverTime() {
      return this.incidentDetail?.handOverTime ? format(this.incidentDetail.handOverTime, 'p') : '-'
    }

    get handOverKind() {
      return this.incidentDetail?.handOverKind
        ? this.$t(`incidentService.updateIncident.handOverKindChoices.${this.incidentDetail.handOverKind}`)
        : null
    }

    getFullName(person: IPersonalData) {
      return person.fullName
    }

    getAddressLine(person: IPersonalData) {
      if (!person) {
        return null
      }

      const { address, city, zipCode, country } = person
      return `${address ? `${address}, ` : ''}${zipCode ?? ''} ${city ?? ''}${country ? `, ${country}` : ''}`
    }

    filterCurrentSite(features: Feature<Geometry>[]) {
      return features.filter(feature => {
        const { id } = feature.getProperties() as ICustomGeojsonProperties

        if (this.incidentDetail) {
          return this.incidentDetail.siteId === id
        } else {
          return false
        }
      })
    }

    toggleReportClass() {
      document.body.classList.toggle(reportClass)
    }
  }
