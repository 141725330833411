
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import Overlay from 'ol/Overlay'
  import { Coordinate } from 'ol/coordinate'

  import { MapContext } from '@/areas/SiteService/components/MapManager/types'
  import { ICoordinatesDto } from '@/api/zbag-prd/issues/types'

  @Component
  export default class PositionMarker extends Vue {
    @Prop() mapContext!: MapContext
    @Prop() coordinate!: ICoordinatesDto

    get olPosition(): Coordinate {
      return [this.coordinate.easting, this.coordinate.northing]
    }

    initMarker() {
      const map = this.mapContext.map!

      if (!map) {
        return
      }

      const positionMarker = this.createMarker()

      if (positionMarker) {
        map.addOverlay(positionMarker)
      }
    }

    mounted() {
      this.initMarker()
    }

    createMarker(): Overlay {
      const markerElement = this.$refs.positionMarker as HTMLElement

      return new Overlay({
        className: 'positionMarker',
        element: markerElement,
        positioning: 'center-center',
        position: this.olPosition,
        offset: [0, -15] // Compensates for the map-marker icon, so that the pointy end is at the map center
      })
    }
  }
