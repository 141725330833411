import BaseApiClient from '@/api/utils/BaseApiClient'
import { IInspection } from '@/areas/SiteService/store/types'
import {
  ICreateInspectionDto,
  IDeleteInspectionRequestDto,
  IGetInspectionRequestDto,
  IGetInspectionsForRegionRequestDto,
  IGetInspectionsForSiteRequestDto,
  IInspectionDto,
  IInspectionListDto
} from '@/api/zbag-prd/inspections/types'
import { hydrateInspections } from '@/api/zbag-prd/inspections/utils'

export default class InspectionsApiClient extends BaseApiClient {
  async postInspection({
    regionId,
    siteId,
    createdAt,
    createdBy,
    notes,
    kind
  }: ICreateInspectionDto): Promise<IInspection | null> {
    const nullableNotes = notes === '' ? null : notes
    const payload = JSON.stringify({
      createdAt: createdAt.toISOString(),
      createdBy,
      notes: nullableNotes,
      kind
    })

    const response = await this.fetch<IInspectionDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/inspections`,
      this.requestOptions('POST', {
        body: payload
      })
    )

    if (response) {
      return hydrateInspections([response])[0]
    }

    return null
  }

  async getInspectionsForRegion({
    kind,
    regionId,
    from,
    to,
    limit = this.defaultResultLimit
  }: IGetInspectionsForRegionRequestDto): Promise<IInspection[]> {
    const queryParams = new URLSearchParams()

    if (kind) {
      queryParams.set('kind', kind)
    }

    if (to) {
      queryParams.set('to', to)
    }

    if (from) {
      queryParams.set('from', from)
    }

    queryParams.set('limit', String(limit))

    const response = await this.fetch<IInspectionListDto>(
      `${this.baseUrl}/regions/${regionId}/inspections?${queryParams.toString()}`,
      this.requestOptions('GET')
    )

    if (response && response.inspections) {
      return hydrateInspections(response.inspections)
    }

    return []
  }

  async getInspection({ kind, regionId, siteId, createdAt }: IGetInspectionRequestDto): Promise<IInspection | null> {
    const response = await this.fetch<IInspectionDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/inspections/${kind}/${createdAt}`,
      this.requestOptions('GET')
    )

    if (response) {
      return hydrateInspections([response])[0]
    }

    return null
  }

  async getInspectionsForSite({
    regionId,
    siteId,
    kind,
    from,
    to,
    limit = this.defaultResultLimit
  }: IGetInspectionsForSiteRequestDto): Promise<IInspection[]> {
    const queryParams = new URLSearchParams()

    if (kind) {
      queryParams.set('kind', kind)
    }

    if (from) {
      queryParams.set('from', from)
    }

    if (to) {
      queryParams.set('to', to)
    }

    queryParams.set('limit', String(limit))

    const response = await this.fetch<IInspectionListDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/inspections?${queryParams.toString()}`,
      this.requestOptions('GET')
    )

    if (response && response.inspections) {
      return hydrateInspections(response.inspections)
    }

    return []
  }

  async deleteInspection({ regionId, kind, createdAt, siteId }: IDeleteInspectionRequestDto): Promise<void> {
    return await this.fetch(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/inspections/${kind}/${createdAt}`,
      this.requestOptions('DELETE')
    )
  }
}
