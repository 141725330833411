import { GetterTree } from 'vuex'

import { IRootState } from '@/store/types'
import { IIncidentServiceState } from '@/areas/IncidentService/store/types'

const getterTree: GetterTree<IIncidentServiceState, IRootState> = {
  incidentsForOverview: state => state.overview.incidents,
  allSites: state => state.sites,
  getSiteById: state => (siteId: string) => state.sites.find(site => site.id === siteId),
  incidentDetail: state => state.incidentDetail,
  currentFilter: state => state.overview.filter || {}
}

export default getterTree
