import { format as dateFormat, formatRelative as dateFormatRelative } from 'date-fns'
import { de } from 'date-fns/locale'
import { ISite } from '@/areas/SiteService/store/types'

export const format = (date: Date, formatStr: string = 'PPp'): string => {
  return dateFormat(date, formatStr, { locale: de })
}

export const formatRelative = (date: Date, baseDate: Date): string => {
  return dateFormatRelative(date, baseDate, { locale: de, weekStartsOn: 1 })
}

export const siteDisplayName = (site: ISite): string => {
  return `${site.name} ${site.number ? `(Nr. ${site.number})` : ''}`
}

export const getUTCDate = (): Date => {
  return new Date(new Date().toUTCString())
}
