
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Assessment } from '@/model'
  import ImagePreview from '@/components/ImagePreview.vue'

  @Component({
    components: {
      ImagePreview
    }
  })
  export default class Forecast extends Vue {
    @Prop()
    public assessment!: Assessment

    @Prop()
    public editMode!: boolean

    public collapsed = true
    public slfInfoCollapsed = true
    public showSlfInfoExpandButton = false
    public weatherDevelopment = ''

    @Watch('assessment.currentSituation.ownObservations.weatherDevelopment', { immediate: true })
    public watchOwnObservations(weatherDevelopment: string) {
      this.weatherDevelopment = weatherDevelopment
    }

    public hasSlfEarlyWarning() {
      return this.assessment.currentSituation.slfEarlyWarning && this.assessment.currentSituation.slfEarlyWarning !== ''
    }

    public updateWeatherDevelopment(development: string) {
      this.weatherDevelopment = development
      this.emitUpdate(development)
    }

    @Emit('update:forecast')
    private emitUpdate(development: string): string {
      return development
    }
  }
