import { IInspection } from '@/areas/SiteService/store/types'
import { parseISO } from 'date-fns'
import { IInspectionDto } from '@/api/zbag-prd/inspections/types'

export const hydrateInspections = (inspections: IInspectionDto[]): IInspection[] => {
  const hydratedInspections = inspections.map(({ createdAt, createdBy, kind, siteId, notes }) => {
    const createdDate = parseISO(createdAt)

    return {
      kind,
      notes,
      siteId,
      createdBy,
      createdAt: createdDate
    }
  })

  return hydratedInspections
}
