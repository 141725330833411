
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import Feature from 'ol/Feature'
  import { Geometry } from 'ol/geom'
  import { groupBy, keys } from 'lodash-es'
  import VectorLayer from 'ol/layer/Vector'
  import { buffer, createEmpty, extend, Extent } from 'ol/extent'
  import LayerGroup from 'ol/layer/Group'
  import { StyleFunction } from 'ol/style/Style'
  import { Fill, Stroke, Style } from 'ol/style'
  import VectorSource from 'ol/source/Vector'

  import { MapContext } from '@/areas/SiteService/components/MapManager/types'
  import { Regions } from '@/model'

  const sitesLayerGroupKey = 'id'
  const sitesLayerGroupId = 'sites'
  const regionLayerKey = 'regionId'
  const colorMap = new Map([
    /* Map user desired colors to something good-looking */
    ['yellow', '#FDD835'],
    ['blue', '#2694ee'],
    ['purple', '#AB47BC'],
    ['black', '#212121'],
    ['red', '#EF5350'],
    ['white', '#ffffff']
  ])

  @Component
  export default class MapSiteLayer extends Vue {
    @Prop() mapContext!: MapContext
    @Prop() features!: Feature<Geometry>[]
    @Prop() selectedRegionId!: string
    @Prop() selectedSiteId?: string
    @Prop({
      type: Boolean,
      default: false
    })
    autoFitExtent!: boolean
    @Prop() visibleRegionIds?: string[]

    @Watch('mapContext', { immediate: true })
    onMapChanged(newMapContext: MapContext) {
      if (newMapContext.map && !this.hasLayerMounted) {
        this.initLayer()
      }
    }

    @Watch('selectedSiteId')
    onSelectedSiteIdChanged(): void {
      const regionLayer = this.getSelectedRegionLayer()

      if (regionLayer) {
        regionLayer.changed() // update layer style
        this.fitExtent() // fit in view
      }
    }

    @Watch('selectedRegionId')
    onRegionIdChanged(): void {
      const regionLayer = this.getSelectedRegionLayer()

      if (this.selectedRegionId === Regions.Winter) {
        this.fitExtent()
        return
      }

      if (regionLayer) {
        regionLayer.changed()
        this.fitExtent()
      }
    }

    @Watch('visibleRegionIds', { deep: true })
    onVisibleRegionIdsChanged(): void {
      const layerGroup = this.getSitesLayerGroup()

      if (layerGroup) {
        layerGroup.getLayersArray().forEach(layer => layer.changed())
      }
    }

    private hasLayerMounted: boolean = false

    initLayer(): void {
      const map = this.mapContext.map!
      const layerGroup = this.createLayerGroup(this.features)

      if (layerGroup) {
        map.addLayer(layerGroup)

        if (this.autoFitExtent) {
          // automatically fit extent if enabled.
          // will fit extent in this order: site, region or zermatt ski area
          map.once('postcompose', this.fitExtent)
        }
      }

      this.hasLayerMounted = true
    }

    getSelectedRegionLayer(): VectorLayer<VectorSource<any>> | null {
      const map = this.mapContext.map

      if (!map) {
        return null
      }

      const sitesLayerGroup = this.getSitesLayerGroup()

      if (!sitesLayerGroup) {
        return null
      }

      const selectedRegionLayer = sitesLayerGroup
        .getLayersArray()
        .find(layer => layer.get(regionLayerKey) === this.selectedRegionId) as VectorLayer<VectorSource<any>>

      return selectedRegionLayer || null
    }

    getSitesLayerGroup(): LayerGroup | null {
      const map = this.mapContext.map

      if (!map) {
        return null
      }

      const sitesLayerGroup = map
        .getLayers()
        .getArray()
        .find(layer => layer.get(sitesLayerGroupKey) === sitesLayerGroupId) as LayerGroup

      return sitesLayerGroup || null
    }

    fitExtent() {
      const map = this.mapContext.map
      let extentToFit: Extent | null = null

      if (!map) {
        return
      }

      const selectedRegionLayer = this.getSelectedRegionLayer()

      if (!selectedRegionLayer) {
        // may be the case for Module 3 (no regions)
        const sitesLayerGroup = this.getSitesLayerGroup()
        const extent = sitesLayerGroup?.getExtent()

        if (sitesLayerGroup && extent) {
          map.getView().fit(buffer(extent, 500))
        }

        return
      }

      const regionLayerSource = selectedRegionLayer.getSource()

      if (!regionLayerSource) {
        return
      }

      if (this.selectedSiteId) {
        const selectedFeature = regionLayerSource
          .getFeatures()
          .find(feature => feature.get('id') === this.selectedSiteId)
        if (selectedFeature) {
          extentToFit = selectedFeature.getGeometry().getExtent()
        }
      } else {
        extentToFit = regionLayerSource.getExtent()
      }

      if (extentToFit) {
        map.getView().fit(buffer(extentToFit, 500), { duration: this.selectedSiteId ? 500 : 0 })
      }
    }

    createStyleMapEntry(baseColor: string, alpha: number): Style {
      return new Style({
        stroke: new Stroke({
          color: baseColor,
          width: 1
        }),
        fill: new Fill({
          color: this.hexToRGB(baseColor, alpha)
        })
      })
    }

    hexToRGB(hex: string, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
      return `rgba(${r},${g},${b},${alpha})`
    }

    createLayerGroup(features: Feature<Geometry>[]): LayerGroup {
      const featureGroupsToLayer = groupBy(features, feature => feature.get('region'))
      const styleMap = new Map()

      // create a collection of colored styles upfront (OL performance tipp)
      colorMap.forEach((value, key) => {
        styleMap.set(key, this.createStyleMapEntry(value, 0.7)) // default style
        styleMap.set(`${key}-faded`, this.createStyleMapEntry(value, 0.1)) // style for when another is active
        styleMap.set(`${key}-active`, this.createStyleMapEntry(value, 0.9)) // style for active site
      })

      const styleFunction: StyleFunction = (feature, resolution) => {
        const isSelectedSite = feature.get('id') === this.selectedSiteId
        const color = feature.get('color') || 'white'
        const regionId = feature.get('region')

        // returning an empty array means the feature won't be rendered
        if (this.visibleRegionIds && !this.visibleRegionIds.includes(regionId)) {
          return []
        }

        if (this.selectedSiteId && isSelectedSite) {
          return [styleMap.get(`${color}-active`)]
        } else if (this.selectedSiteId) {
          // when a particular site is selected, all other sites should fade out
          return [styleMap.get(`${color}-faded`)]
        } else {
          return [styleMap.get(color)]
        }
      }

      const layers = keys(featureGroupsToLayer).map(regionId => {
        const vectorLayer = new VectorLayer({
          source: new VectorSource({ features: featureGroupsToLayer[regionId] }),
          style: styleFunction
        })

        // assign region to 'regionId' so that the layer can be retrieved by that later
        vectorLayer.set(regionLayerKey, regionId)

        return vectorLayer
      })

      // create extent that will hold the extent of all features combined
      const combinedExtent = createEmpty()

      // extract extent from each vector layer and merge onto combined extent
      layers.forEach(layer => extend(combinedExtent, layer.getSource()?.getExtent() ?? []))

      // create new layer group that contains features for the entire ski area, including its extent
      const layerGroup = new LayerGroup({ layers, extent: combinedExtent })

      // assign a name for easier reference later
      layerGroup.set(sitesLayerGroupKey, sitesLayerGroupId)

      return layerGroup
    }
  }
