import * as Sentry from '@sentry/browser'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'

import { createOidcSettings } from '@/auth-settings'
import { Role } from '@/model'
import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import store from '@/store'
import { ZbagUser } from '@/areas/Oidc/store/types'

export function createOidcStore(oidcAuthority: string, rootUrl: string): Module<any, IRootState> {
  const oidcStoreModule = vuexOidcCreateStoreModule(
    createOidcSettings(oidcAuthority, rootUrl),
    { namespaced: true },
    {
      userLoaded: (user: ZbagUser) => {
        Sentry.configureScope(scope => scope.setUser({ id: user.profile.sub }))

        store.commit('setCurrentUser', user)
      },
      silentRenewError: () => {}
    }
  )

  return {
    ...oidcStoreModule,
    getters: {
      ...oidcStoreModule.getters,
      hasRole: state => (allowedRoles: Role | Role[]): boolean => {
        if (!state.user) {
          // avoid an exception on logout
          return false
        }

        const realmAccess = state.user.realm_access

        if (!realmAccess) {
          throw new Error('ID Token is missing "realm_access" information. Configure this in keycloak!')
        }

        const userRoles: string[] = realmAccess.roles

        if (Array.isArray(allowedRoles)) {
          let atLeastOneMatches = false

          allowedRoles.forEach(allowedRole => {
            if (userRoles.includes(allowedRole.toString())) {
              atLeastOneMatches = true
            }
          })

          return atLeastOneMatches
        }

        return userRoles.includes(allowedRoles.toString())
      },
      hasAccessToRegion: state => (regionId: string) => {
        const allowedRegions = state.user?.zbag?.patroller_regions as string[]

        if (!allowedRegions) {
          return false
        }

        return allowedRegions.includes(regionId)
      },
      allowedRegions: state => (state.user?.zbag?.patroller_regions as string[]) ?? []
    }
  }
}
