
  import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
  import ExpositionSelector from '@/components/avalanche-situation/ExpositionSelector.vue'
  import { IAvalancheProblems } from '@/model'
  import { cloneDeep } from 'lodash-es'
  import { LocaleMessage } from 'vue-i18n'

  @Component({
    components: { ExpositionSelector }
  })
  export default class AvalancheProblems extends Vue {
    @Prop()
    public editMode!: boolean

    @Prop()
    public problemChoices!: any[]

    @Prop()
    public avalancheProblemsProp!: IAvalancheProblems | null

    @Prop()
    public type!: string

    public switchState: boolean = false

    public displayRemoveDialog: boolean = false

    private avalancheProblemsLocal: IAvalancheProblems | null = null

    private readonly newProblems: IAvalancheProblems = {
      expositions: [],
      problems: [],
      altitude: 0
    }

    /**
     * Sync local state with property which is updated via store.
     */
    @Watch('avalancheProblemsProp', { immediate: true, deep: true })
    public onAvalancheProblemsChanged(avalancheProblemsFromProps: IAvalancheProblems) {
      if (avalancheProblemsFromProps) {
        this.avalancheProblemsLocal = { ...avalancheProblemsFromProps }
        this.switchState = true
      } else {
        this.avalancheProblemsLocal = null
        this.switchState = false
      }
    }

    @Emit('update:avalanche-problems')
    public emitUpdate(avalancheProblems: IAvalancheProblems | null): IAvalancheProblems | null {
      return avalancheProblems
    }

    get prefix() {
      return this.$t(`dailyAssessment.conditions.avalancheSituation.customAssessment.problems.${this.type}.prefix`)
    }

    get suffix() {
      return this.$t(`dailyAssessment.conditions.avalancheSituation.customAssessment.problems.${this.type}.suffix`)
    }

    public get problems(): string[] {
      return this.avalancheProblemsLocal !== null ? this.avalancheProblemsLocal.problems : []
    }

    public set problems(problems: string[]) {
      if (this.avalancheProblemsLocal !== null) {
        this.avalancheProblemsLocal.problems = problems
        this.emitUpdate(this.avalancheProblemsLocal)
      }
    }

    public toggleAvalancheProblems(status: boolean) {
      if (status) {
        this.switchState = true
        this.resetLocalProblems()
      } else {
        this.switchState = false
        this.displayRemoveDialog = true
      }
    }

    public deleteAvalancheProblems() {
      // explicitly set null here because if we have a local change only which is not yet in the
      // store we will never receive a watch event.
      this.avalancheProblemsLocal = null
      this.displayRemoveDialog = false
      this.switchState = false
      this.emitUpdate(null)
    }

    public cancelAvalancheProblemsDeletion() {
      this.displayRemoveDialog = false
      this.switchState = true
    }

    public validateAltitude(value: number): boolean | LocaleMessage {
      return (
        this.isValidAltitude(value) ||
        this.$t('dailyAssessment.conditions.avalancheSituation.customAssessment.problems.invalidInputAltitude')
      )
    }

    public isValidAltitude(value: number): boolean | LocaleMessage {
      const altitudePattern = /^\d{0,4}$/g
      const isEmpty = value.toString() === ''
      const isNull = value === 0
      return !isEmpty && !isNull && altitudePattern.test(value.toString())
    }

    public onAltitudeBlur() {
      if (this.avalancheProblemsLocal !== null && this.isValidAltitude(this.avalancheProblemsLocal.altitude)) {
        this.emitUpdate(this.avalancheProblemsLocal)
      }
    }

    public updateExpositions(expositions: string[]) {
      if (this.avalancheProblemsLocal !== null) {
        this.avalancheProblemsLocal.expositions = expositions
        this.emitUpdate(this.avalancheProblemsLocal)
      }
    }

    private resetLocalProblems() {
      // resetting by making a deep copy which won't change the orginal empty template
      this.avalancheProblemsLocal = cloneDeep(this.newProblems)
    }
  }
