
  import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue'
  import DataPointViz from '@/components/DataPointViz.vue'
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { TemperatureStation } from '@/model'

  @Component({
    components: {
      DataPointViz,
      DataPointListTimeViz
    }
  })
  export default class DenseTemperatureStationView extends Vue {
    items!: TemperatureStation[]

    @Prop() stationValues!: TemperatureStation[]

    @Watch('stationValues', { immediate: true })
    onStationValuesChanged(newValues: TemperatureStation[]) {
      this.items = newValues
    }
  }
