
  // noinspection TypeScriptCheckImport
  import * as Highcharts from 'highcharts'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import ResizeObserver from 'resize-observer-polyfill'
  import { TemperatureStation, ChartDisplayTimeRange } from '@/model'
  import { fromUnixTime } from 'date-fns/esm'
  import DataPointViz from '@/components/DataPointViz.vue'
  import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue'
  import { categoryColors, fillColors } from '@/components/definitions'
  import { format } from '@/plugins/format'

  const chartCache = new WeakMap()

  @Component({
    components: { DataPointListTimeViz, DataPointViz }
  })
  export default class TemperatureChart extends Vue {
    @Prop()
    public station!: TemperatureStation

    @Prop()
    public displayTimeRange?: ChartDisplayTimeRange<Date>

    private resizeObserver!: ResizeObserver

    @Watch('displayTimeRange')
    onDisplayTimeRangeChanged(newRange: ChartDisplayTimeRange<Date>) {
      const chart = chartCache.get(this)

      if (chart) {
        chart.update({
          xAxis: {
            min: this.defaultDisplayTimeRange.from,
            max: this.defaultDisplayTimeRange.to
          }
        })
      }
    }

    get defaultDisplayTimeRange(): ChartDisplayTimeRange<number | null> {
      const from = this.displayTimeRange ? this.displayTimeRange.from.valueOf() : null
      const to = this.displayTimeRange ? this.displayTimeRange.to.valueOf() : null

      return {
        from,
        to
      }
    }

    public mounted() {
      let chartElement = this.$refs.chart as any
      this.createChart(chartElement)
    }

    public destroyed() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
      }
    }

    private formatTooltip(unit: string, decimals: number = 1): () => string {
      return function(this: Highcharts.TooltipFormatterContextObject) {
        const time = format(fromUnixTime(this.x / 1000), 'PPp')
        return `<b>${this.series.name}</b><br>
                    <small>${time}</small><br>
                    <b>${this.y.toFixed(decimals)} ${unit}</b>`
      }
    }

    private createChart(element: any) {
      let series = [
        {
          type: 'area',
          name: this.$t('dailyAssessment.conditions.temperatureSituation.chartHumidity') as string,
          color: fillColors[0],
          fillColor: fillColors[0],
          yAxis: 1,
          zIndex: 100,
          tooltip: {
            pointFormatter: this.formatTooltip('%', 0)
          },
          data: this.station.airHumidity.map(datapoint => [datapoint.timestamp.valueOf(), datapoint.value]).sort()
        },
        {
          type: 'line',
          name: this.$t('dailyAssessment.conditions.temperatureSituation.chartAir') as string,
          color: categoryColors[0],
          yAxis: 0,
          zIndex: 1000,
          tooltip: {
            pointFormatter: this.formatTooltip('°C')
          },
          data: this.station.airTemperature.map(datapoint => [datapoint.timestamp.valueOf(), datapoint.value]).sort()
        },
        {
          type: 'line',
          name: this.$t('dailyAssessment.conditions.temperatureSituation.chartSnowSurface') as string,
          color: categoryColors[2],
          dashStyle: 'Dash',
          yAxis: 0,
          zIndex: 200,
          tooltip: {
            pointFormatter: this.formatTooltip('°C')
          },
          data: this.station.snowSurfaceTemperature
            .map(datapoint => [datapoint.timestamp.valueOf(), datapoint.value])
            .sort()
        },
        {
          type: 'line',
          name: this.$t('dailyAssessment.conditions.temperatureSituation.chartSnow') as string,
          color: categoryColors[2],
          yAxis: 0,
          zIndex: 300,
          tooltip: {
            pointFormatter: this.formatTooltip('°C')
          },
          data: this.station.snowPackTemperature
            .map(datapoint => [datapoint.timestamp.valueOf(), datapoint.value])
            .sort()
        }
      ]

      const chart = Highcharts.chart(
        element,
        {
          title: {
            text: this.station.stationName
          },
          time: {
            timezoneOffset: new Date().getTimezoneOffset()
          },
          chart: {
            height: 250,
            spacingBottom: 0
          },
          credits: undefined,
          plotOptions: {
            line: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            headerFormat: ''
          },
          xAxis: {
            type: 'datetime',
            min: this.defaultDisplayTimeRange.from,
            max: this.defaultDisplayTimeRange.to
          },
          yAxis: [
            {
              title: {
                text: `${this.$t('dailyAssessment.conditions.temperatureSituation.chartTemperature')} (°C)`
              }
            },
            {
              title: {
                text: `${this.$t('dailyAssessment.conditions.temperatureSituation.chartHumidity')} (%)`
              },
              opposite: true,
              endOnTick: false,
              max: 100
            }
          ],
          legend: {
            enabled: true
          },
          series: series as any
        },
        undefined
      )

      chartCache.set(this, chart)

      if (ResizeObserver) {
        this.resizeObserver = new ResizeObserver(() => chart.reflow())
        this.resizeObserver.observe(element)
      }
    }
  }
