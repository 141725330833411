
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { ITemperatureSituation } from '@/model'
  import DataPointViz from '@/components/DataPointViz.vue'
  import MeasurementTable from '@/components/MeasurementTable.vue'
  import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue'
  import DenseTemperatureStationView from '@/components/DenseTemperatureStationView.vue'
  import { ChartDisplayTimeRange } from '@/model'
  import ExpandableCharts from '@/components/ExpandableCharts.vue'

  @Component({
    components: {
      DenseTemperatureStationView,
      DataPointListTimeViz,
      MeasurementTable,
      DataPointViz,
      ExpandableCharts
    }
  })
  export default class TemperatureSituation extends Vue {
    @Prop()
    public temperatureSituation!: ITemperatureSituation

    @Prop()
    public displayTimeRange!: ChartDisplayTimeRange<Date>

    public get isDenseScreen(): boolean {
      return this.$vuetify.breakpoint.xsOnly
    }

    public get headers() {
      return [
        {
          text: this.$t('dailyAssessment.conditions.temperatureSituation.station'),
          value: 'stationName',
          align: 'start'
        },
        {
          text: this.$t('dailyAssessment.conditions.temperatureSituation.airTemperature'),
          value: 'airTemperature.value',
          align: 'end'
        },
        {
          text: this.$t('dailyAssessment.conditions.temperatureSituation.airHumidity'),
          value: 'airHumidity.value',
          align: 'end'
        },
        {
          text: this.$t('dailyAssessment.conditions.temperatureSituation.snowSurfaceTemperature'),
          value: 'snowSurfaceTemperature.value',
          align: 'end'
        },
        {
          text: this.$t('dailyAssessment.conditions.temperatureSituation.snowPackTemperature'),
          value: 'snowPackTemperature.value',
          align: 'end'
        }
      ]
    }
  }
