import {
  Assessment,
  IDataPoint,
  IFileResource,
  OwnObservations,
  SnowStation,
  TemperatureStation,
  WindStation
} from '@/model'
import { parseISO } from 'date-fns'

export function hydrateFileResource(fileResource: any): IFileResource {
  return {
    url: fileResource.url,
    timestamp: parseISO(fileResource.timestamp)
  }
}

export function hydrateDataPoints(dataPoints: any[], valueConverter: (v: any) => number = v => v): IDataPoint[] {
  return dataPoints.map((d: any) => {
    return {
      value: valueConverter(d.value),
      timestamp: parseISO(d.timestamp)
    }
  })
}

export function hydrateNullableFileResource(fileResource: any): IFileResource | null {
  return fileResource === null ? null : hydrateFileResource(fileResource)
}

export function reconstituteAssessment(dto: any, stationMapping: Map<string, string>) {
  /**
   * Resolves the station name for the given station id. If no entry exists,
   * the specified station id is returned.
   * @param stationId
   */
  const getStationName = (stationId: string) => {
    const stationName = stationMapping.get(stationId)

    return stationName === undefined ? stationId : stationName
  }

  const currentSituation = dto.currentSituation
  const ownObservations = currentSituation.ownObservations
  const courseOfAction = dto.courseOfAction
  const requiredMeasures = dto.courseOfAction === null ? null : courseOfAction.requiredMeasures

  return new Assessment(
    dto.region,
    parseISO(dto.createdAt),
    dto.createdBy,
    dto.finalizedBy,
    dto.finalizedAt ? parseISO(dto.finalizedAt) : null,
    {
      ownObservations: new OwnObservations(
        ownObservations.snowLine,
        ownObservations.weatherObservations,
        ownObservations.cloudObservation,
        ownObservations.windStrengthObservation,
        ownObservations.windDirectionObservation,
        ownObservations.snowPackObservations,
        ownObservations.avalancheActivityObservations,
        ownObservations.avalancheProblemObservations,
        ownObservations.notes,
        ownObservations.weatherDevelopment,
        ownObservations.customAvalancheBulletin
      ),
      attachments: currentSituation.attachments,
      weatherForecast: Object.freeze({
        meteoMediaResources: currentSituation.weatherForecast.meteoMediaResources.map((resource: any) =>
          hydrateFileResource(resource)
        )
      }),
      slfEarlyWarning: currentSituation.slfEarlyWarning,
      avalancheBulletin: {
        bulletin: hydrateNullableFileResource(currentSituation.avalancheBulletin.bulletin),
        iconMap: hydrateNullableFileResource(currentSituation.avalancheBulletin.iconMap)
      },
      snow: Object.freeze({
        stationValues: currentSituation.snow.dataPoints.map((s: any) => {
          return new SnowStation(
            getStationName(s.stationId),
            s.stationId,
            s.isPrimary,
            hydrateDataPoints(s.freshSnow24H, v => v * 100 /* convert m to cm */),
            hydrateDataPoints(s.freshSnow72H, v => v * 100 /* convert m to cm */),
            hydrateDataPoints(s.snowDepth, v => v * 100 /* convert m to cm */)
          )
        })
      }),
      wind: Object.freeze({
        stationValues: currentSituation.wind.dataPoints.map((s: any) => {
          return new WindStation(
            getStationName(s.stationId),
            s.stationId,
            s.isPrimary,
            hydrateDataPoints(s.windAverage3H),
            hydrateDataPoints(s.windAverage10Min),
            hydrateDataPoints(s.windPeak),
            hydrateDataPoints(s.windDirection)
          )
        })
      }),
      temperature: Object.freeze({
        stationValues: currentSituation.temperature.dataPoints.map((s: any) => {
          return new TemperatureStation(
            getStationName(s.stationId),
            s.stationId,
            s.isPrimary,
            hydrateDataPoints(s.airTemperature),
            hydrateDataPoints(s.airHumidity),
            hydrateDataPoints(s.snowSurfaceTemperature),
            hydrateDataPoints(s.snowPackTemperature)
          )
        })
      })
    },
    {
      notes: courseOfAction.notes,
      nextPlannedAssessment:
        courseOfAction.nextPlannedAssessment === null ? null : parseISO(courseOfAction.nextPlannedAssessment),
      requiredMeasures:
        requiredMeasures === null
          ? null
          : {
              affectedPartOfRegion: requiredMeasures.affectedPartOfRegion,
              deployWarningSigns: requiredMeasures.deployWarningSigns,
              actionPackages: requiredMeasures.actionPackages
            },
      availableActionPackages: courseOfAction.availableActionPackages
    },
    dto.changeLog.map((l: any) => ({
      date: parseISO(l.date),
      changes: l.changes.map((c: any) => ({
        timestamp: parseISO(c.timestamp),
        changeBy: c.changeBy,
        from: c.from,
        to: c.to,
        property: c.property
      }))
    }))
  )
}
