
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { IDataPoint } from '@/model'
  import CustomInlineSvg from '@/components/CustomInlineSvg.vue'

  @Component({
    components: {
      CustomInlineSvg
    }
  })
  export default class DataPointViz extends Vue {
    @Prop()
    public dataPoint!: IDataPoint

    @Prop({ default: 'text' })
    public mode!: boolean

    @Prop({ default: 0 })
    public decimals!: number

    @Prop({ default: '' })
    public unit!: string

    @Prop(Boolean) // for 'small' to be casted to Boolean
    public small!: boolean

    public get windDirectionAbbreviation() {
      if (!this.dataPoint) {
        return ''
      }

      let abbreviations = ['N', 'NO', 'O', 'SO', 'S', 'SW', 'W', 'NW']
      let interval = 360 / abbreviations.length + 1

      let index = Math.floor(((this.dataPoint.value % 360) + interval / 2) / interval) % abbreviations.length
      return abbreviations[index]
    }
  }
