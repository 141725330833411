import { ISiteDto } from '@/api/zbag-prd/sites/types'
import { ISite, IStatistics } from '@/areas/SiteService/store/types'
import { parseISO } from 'date-fns'

export const hydrateSites = (sites: ISiteDto[]): ISite[] =>
  sites.map(site => {
    let statistics: IStatistics | undefined = undefined

    if (site.statistics) {
      statistics = {
        lastTechnicalInspection: parseISO(site.statistics.lastTechnicalInspection),
        lastFinalInspection: parseISO(site.statistics.lastFinalInspection),
        openIssuesCount: site.statistics.openIssuesCount
      }
    }

    return {
      ...site,
      statistics: statistics || null
    }
  })
