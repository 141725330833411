
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { IWindSituation } from '@/model'
  import DataPointViz from '@/components/DataPointViz.vue'
  import { ChartDisplayTimeRange } from '@/model'
  import MeasurementTable from '@/components/MeasurementTable.vue'
  import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue'
  import DenseWindStationView from '@/components/DenseWindStationView.vue'
  import ExpandableCharts from '@/components/ExpandableCharts.vue'

  @Component({
    components: {
      DenseWindStationView,
      DataPointListTimeViz,
      MeasurementTable,
      DataPointViz,
      ExpandableCharts
    }
  })
  export default class WindSituation extends Vue {
    @Prop()
    public windSituation!: IWindSituation

    @Prop()
    public displayTimeRange!: ChartDisplayTimeRange<Date>

    public get isDenseScreen(): boolean {
      return this.$vuetify.breakpoint.xsOnly
    }

    public get headers() {
      return [
        {
          text: this.$t('dailyAssessment.conditions.windSituation.station'),
          value: 'stationName',
          align: 'start'
        },
        {
          text: this.$t('dailyAssessment.conditions.windSituation.windAverage3h'),
          value: 'average3h.value',
          align: 'end'
        },
        {
          text: this.$t('dailyAssessment.conditions.windSituation.windAverage10min'),
          value: 'average.value',
          align: 'end'
        },
        {
          text: this.$t('dailyAssessment.conditions.windSituation.windPeak'),
          value: 'peak.value',
          align: 'end'
        },
        {
          text: this.$t('dailyAssessment.conditions.windSituation.windDirection'),
          value: 'direction.value',
          align: 'end'
        }
      ]
    }

    get hasSecondaryStations() {
      const secondaryStations = this.windSituation.stationValues.filter(item => !item.isPrimary)
      return secondaryStations.length > 0
    }
  }
